export const opcoesProdutos = {
  vazio: {
    value: "Selecione um produto",
    label: "Selecione um produto",
    param: "vazio",
  },
  expresso: {
    value: "Catálogo Expresso Instalado",
    label: "Catálogo Expresso Instalado",
    param: "expresso",
  },
  mobile: {
    value: "Catálogo Expresso Mobile",
    label: "Catálogo Expresso Mobile",
    param: "mobile",
  },
  impresso: {
    value: "Catálogo Impresso",
    label: "Catálogo Impresso",
    param: "impresso",
  },
  personalizado: {
    value: "Catálogo Personalizado",
    label: "Catálogo Personalizado",
    param: "personalizado",
  },
  desenhos: {
    value: "Desenhos e Ilustrações Técnicas",
    label: "Desenhos e Ilustrações Técnicas",
    param: "desenhos",
  },
}
